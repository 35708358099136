/*
 * Copyright 2017 - 2019, Joachim Kuebart <joachim.kuebart@gmail.com>
 *
 * Redistribution and use in source and binary forms, with or without
 * modification, are permitted provided that the following conditions are met:
 *
 *   1. Redistributions of source code must retain the above copyright
 *      notice, this list of conditions and the following disclaimer.
 *
 *   2. Redistributions in binary form must reproduce the above copyright
 *      notice, this list of conditions and the following disclaimer in the
 *      documentation and/or other materials provided with the
 *      distribution.
 *
 *   3. Neither the name of the copyright holder nor the names of its
 *      contributors may be used to endorse or promote products derived
 *      from this software without specific prior written permission.
 *
 * THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS "AS IS"
 * AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
 * IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE
 * ARE DISCLAIMED. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR CONTRIBUTORS BE
 * LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR
 * CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF
 * SUBSTITUTE GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
 * INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER IN
 * CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE)
 * ARISING IN ANY WAY OUT OF THE USE OF THIS SOFTWARE, EVEN IF ADVISED OF THE
 * POSSIBILITY OF SUCH DAMAGE.
 */

/*jslint
    bitwise, browser, devel, this
*/

import "normalize.css/normalize.css";
import "leaflet/dist/leaflet.css";
import "../css/gpxmap.css";

import "leaflet/dist/images/layers-2x.png";
import "leaflet/dist/images/layers.png";

import {
    control,
    DomEvent,
    DomUtil,
    GeoJSON,
    latLng,
    latLngBounds,
    layerGroup,
    map,
    tileLayer
} from "leaflet";
import vectorTileLayer from "leaflet-vector-tile-layer";
import {summaryPane} from "./summary.js";

const CSS = {
    "DETAILS": "gpxmap-details",
    "HBOX": "gpxmap-hbox",
    "SELECT": "gpxmap-select",
    "TRACK": "gpxmap-track",
    "VBOX": "gpxmap-vbox",
    "VIEW": "gpxmap-view"
};
export {CSS};

/**
 * Take a list of keys and return an object where each key maps to true.
 */
const toMap = (keys) => keys.reduce(
    function (map, key) {
        map[key] = true;
        return map;
    },
    {}
);

/**
 * If this looks familiar that's because it's binary search. We find
 * 0 <= i <= array.length such that !pred(i - 1) && pred(i) under the
 * assumption !pred(-1) && pred(length).
 */
function search(array, pred) {
    let le = -1;
    let ri = array.length;
    while (1 + le !== ri) {
        const mi = le + ((ri - le) >> 1);
        if (pred(array[mi])) {
            ri = mi;
        } else {
            le = mi;
        }
    }
    return ri;
}

/// Take an array of tagged template string arguments and an object.
const formatTemplate = ([raw, ...keys], data) => String.raw(
    {raw}, keys.map((key) => data[key])
);

function yearColour(idx) {
    return "hsl(" + [
        `${130 + 50 * (idx >> 1)}`,
        "100%",
        `${27 * (1 + idx % 2)}%`
    ].join(", ") +
    ")";
}

function walkPopup(date, walk, options) {
    const idx = search(walk.dates, (d) => date <= d);

    if (walk.dates[idx] !== date) {
        console.log("walkPopup", date, walk);
        return;
    }

    const subtitles = [
        [
            Number(date.substr(8, 2)),
            Number(date.substr(5, 2)),
            Number(date.substr(0, 4))
        ].join("/"),
        `${Math.round(walk.distances[idx] / 100) / 10}km`
    ];

    if (walk.walkers) {
        subtitles.push(`${walk.walkers} walkers`);
    }

    if (walk.categories) {
        subtitles.push(walk.categories.join(" — "));
    }

    if (options.gpx?.slice(1).every((p) => Object.hasOwn(walk, p))) {
        const gpx = document.createElement("a");
        gpx.setAttribute("href", formatTemplate(options.gpx, walk));
        gpx.textContent = "GPX";
        subtitles.push(gpx);
    }

    if (walk.link) {
        const anchor = document.createElement("a");
        anchor.setAttribute("href", walk.link);
        anchor.setAttribute("target", "_blank");
        anchor.textContent = "blog";
        subtitles.push(anchor);
    }

    const subs = subtitles.reduce(function (subs, node) {
        if (!(node instanceof Node)) {
            node = document.createTextNode(node);
        }
        if (subs.firstChild) {
            subs.appendChild(document.createTextNode(" — "));
        }
        subs.appendChild(node);
        return subs;
    }, document.createElement("div"));

    const popup = document.createDocumentFragment();
    popup.appendChild(subs);

    if (walk.people) {
        const ppl = document.createElement("p");
        ppl.textContent = walk.people.sort().join(" • ");
        popup.appendChild(ppl);
    }

    return {"title": walk.title, "content": popup};
}

function getFeatureId(walk) {
    return walk.properties.date;
}

function locationState(key, value) {
    const state = window.history.state || {};

    if (undefined !== key) {
        state[key] = value;
        window.history.replaceState(state, window.document.title);
    }

    return state;
}

function locationItem(key) {
    return function (...values) {
        if (0 === values.length) {
            return locationState()[key];
        }

        locationState(key, values[0]);
        return values[0];
    };
}

function savedLocation(l) {
    if (undefined === l) {
        return locationState()["@"];
    }

    const {lat, lng} = latLng(l);
    locationState("@", [lat, lng].map((f) => Number(f.toFixed(6))));
    return l;
}

const savedZoom = locationItem("z");
const selected = locationItem("s");

function hiddenYear(year, hidden) {
    const state = [].concat(locationState().y || []);
    if (undefined === hidden) {
        return 0 <= state.indexOf(`${year}`);
    }

    const years = toMap(state);
    years[year] = hidden;
    const newState = Object.keys(years).filter((k) => years[k]).sort();
    locationState(
        "y",
        newState.length
        ? newState
        : undefined
    );
    return hidden;
}

function gpxMap(id, options) {
    // Create all configured tile layers.
    const tileLayers = options.tileLayers.map(function (layer) {
        return {
            "name": layer.name,
            "tileLayer": tileLayer(layer.url, layer.options)
        };
    });

    // Create layers control.
    const layersControl = control.layers(null, null, {"hideSingleBase": true});
    tileLayers.forEach(function (layer) {
        layersControl.addBaseLayer(layer.tileLayer, layer.name);
    });

    // Create the DOM structure for our map.
    const domContainer = DomUtil.get(id);
    DomUtil.addClass(domContainer, CSS.VBOX);

    // Create map with an initial tile layer and layers control.
    const gpxmap = map(
        DomUtil.create("div", CSS.VIEW, domContainer)
    ).addControl(
        control.scale()
    ).addControl(
        layersControl
    ).addLayer(
        tileLayers[0].tileLayer
    );

    const domDetails = DomUtil.create("div", CSS.DETAILS, domContainer);
    function renderDetails({title, content}) {
        const h3 = DomUtil.create("h3");

        h3.textContent = title;
        DomUtil.empty(domDetails);
        domDetails.appendChild(h3);
        domDetails.appendChild(content);
    }

    let hover;
    function trackStyle(feature) {
        const date = feature.properties.date;
        const year = date.substr(0, 4);
        return {
            "className": CSS.TRACK,
            "color": yearColour(year - 2011),
            "opacity": (
                hiddenYear(year)
                ? 0
                : (
                    date === hover
                    ? 1
                    : 0.8
                )
            ),
            "weight": (
                date === hover
                ? 4
                : (
                    date === selected()
                    ? 3.5
                    : 2
                )
            ),
            "interactive": false
        };
    }
    function mouseStyle(feature) {
        const year = feature.properties.date.substr(0, 4);
        return {
            "opacity": 0,
            "weight": 20,
            "interactive": !hiddenYear(year)
        };
    }

    // This layer shows walking tracks.
    const walkLayer = vectorTileLayer(
        options.url,
        {
            "pane": "overlayPane",
            "maxDetailZoom": 13,
            getFeatureId,
            "style": trackStyle
        }
    ).addTo(gpxmap);

    // This layer has invisible mouse-responsive tracks.
    const mouseLayer = vectorTileLayer(
        options.url,
        {
            "pane": "overlayPane",
            "maxDetailZoom": 13,
            getFeatureId,
            "style": mouseStyle,
            "interactive": true // for Leaflet.VectorGrid
        }
    ).on("mouseover", function (evt) {
        hover = getFeatureId(evt.layer);
        walkLayer.setStyle(trackStyle);
    }).on("mouseout", function () {
        hover = undefined;
        walkLayer.setStyle(trackStyle);
    }).addTo(gpxmap);

    fetch(options.index).then(
        (response) => response.json()
    ).then(function (walks) {
        // Collect all years.
        const years = toMap(walks.flatMap(
            (walk) => walk.dates
        ).map(
            (date) => date.substr(0, 4)
        ));

        // Set up a summary pane that reacts when years are toggled.
        function renderSummary() {
            const date = selected();

            if (date) {
                const idx = search(walks, (walk) => date < walk.dates[0]) - 1;
                const popup = walkPopup(date, walks[idx], options);
                if (popup) {
                    return renderDetails(popup);
                }
            }

            return renderDetails({
                "title": options.title,
                "content": this.render()
            });
        }
        const sumPane = summaryPane(walks, renderSummary);

        function deselect() {
            if (selected()) {
                selected(undefined);
                walkLayer.setStyle(trackStyle);
                renderSummary.call(sumPane);
            }
        }
        gpxmap.on("click", deselect);

        // Build popup from matching index entry.
        mouseLayer.on("click", function (evt) {
            const date = getFeatureId(evt.layer);

            DomEvent.stopPropagation(evt);
            if (date === selected()) {
                return;
            }
            deselect();
            selected(date);
            walkLayer.setStyle(trackStyle);

            renderSummary.call(sumPane);
        });

        // Create one layer group per year and add to the map.
        Object.keys(years).forEach(function (year) {
            const lg = layerGroup().on("add", function () {
                if (hiddenYear(year)) {
                    hiddenYear(year, false);
                    walkLayer.setStyle(trackStyle);
                    mouseLayer.setStyle(mouseStyle);
                }
            }).on("remove", function () {
                if (!hiddenYear(year)) {
                    hiddenYear(year, true);
                    walkLayer.setStyle(trackStyle);
                    mouseLayer.setStyle(mouseStyle);
                }
            });
            if (!hiddenYear(year)) {
                gpxmap.addLayer(lg);
            }
            layersControl.addOverlay(lg, year);
            sumPane.addLayer(lg, year);
        });

        // Adjust the map's viewport when all GPX tracks are loaded
        const bounds = latLngBounds(
            walks.flatMap(
                (walk) => walk.bboxes
            ).flatMap(function (bbox) {
                const l = bbox.length >> 1;
                return GeoJSON.coordsToLatLngs([
                    [bbox[0], bbox[1]], [bbox[l], bbox[1 + l]]
                ]);
            })
        );
        gpxmap.setMaxBounds(bounds.pad(0.5));
        if (0 <= savedZoom()) {
            try {
                gpxmap.setView(savedLocation(), savedZoom());
            } catch (exc) {
                console.error(exc);
                gpxmap.fitBounds(bounds);
            }
        } else {
            gpxmap.fitBounds(bounds);
        }
        gpxmap.on("moveend", function () {
            savedLocation(this.getCenter());
            savedZoom(this.getZoom());
        });
    });

    return gpxmap;
}
export {gpxMap};
